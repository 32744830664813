.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.minting-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 320px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #bbbbbb;
  border-radius: 8px;
  color: white;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

button {
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  border: none;
  background-color: #9b965f;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}
button:hover {
  background-color: #79754a;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: black;
}
.gold {
  color: #9b965f;
}